/* Global Values */
.header {
  position: relative;
}
.inner-banner {
  margin-top: 0;
}
.mbss-repeater-wrapper {
  min-height: 50px;
  overflow: hidden;
}
.logoWrapper,
.logoWrapper div {
  width: 171px;
  float: left;
}
img {
  max-width: 100%;
}
div.p {
  font-size: 13px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 5px;
}
div.paragraph-lighter {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  margin-bottom: 0;
}
footer div.p {
  margin-bottom: 0;
}
footer a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
  -moz-transition: color 200ms ease 0s;
  -o-transition: color 200ms ease 0s;
  -webkit-transition: color 200ms ease 0s;
  transition: color 200ms ease 0s;
}
div.paragraph-ttile {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
}
img.logo,
.logo {
  padding: 15px;
  position: relative;
}
.the-website {
  height: 100%;
  min-height: 100%;
}
.homeslider.banneroverlay {
  max-height: 1000px;
}
.banneroverlay .center-container,
.parallax .w-container {
  /*background-color: rgba(0, 0, 0, 0.33);
    padding:20px;*/
}
/*.w-slider-mask .banner:first-child{
    background-attachment: fixed;
}*/
.banner .image {
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.homeslider h2 {
  font-size: 14px;
}
.big-title-banner,
.sub-text-title,
.big-title-banner-blue,
.sub-text-title-blue {
  display: inline-block;
  text-transform: none;
  margin: 0;
  width: 100%;
}
.big-title-banner-blue {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 68px;
  line-height: 80px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: #71BBC8;
}
.sub-text-title-blue {
  font-family: "Open Sans", sans-serif;
  color: #71BBC8;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 7px;
  text-transform: uppercase;
}
.iconMonster {
  width: 50px;
}
.service-wrapper img {
  width: 260px;
}
.left-service-wrapper {
  overflow: visible;
  padding-left: 90px;
  /*color: #999;*/
}
.left-service-wrapper h4 {
  color: black;
}
.left-service {
  min-height: 300px;
}
.aboutSlider h3 {
  color: white;
  display: inline-block;
  padding: 12px 22px;
}
.aboutSlider .banner {
  height: 500px;
  text-align: center;
}
.teamItem {
  margin-top: 50px;
}
.lightingSlider h3 {
  background-color: #f4f8d3;
  color: black;
}
.maintenanceSlider h3 {
  background-color: #d0e15f;
  color: white;
}
.faq-item {
  margin-top: 20px;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(2) .mbss-content {
  padding: 20px 15px;
  background: #dddddd;
  min-width: 100%;
  min-height: 0 !important;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) .mbss-content {
  color: white;
  display: block;
  font-size: 17px;
  padding: 15px !important;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.irrigationFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #71bbc8;
}
.maintenanceFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #d0e15f;
}
.lightingFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #f4f8d3;
}
.lightingFaqItem div.mbss-listItem-text-wrapper:first-child .mbss-content {
  color: #333;
}
.mbss-text-fader-wrapper.w-slider {
  min-height: 50px;
  position: relative;
  background-color: transparent;
  width: auto;
  color: white;
  font-size: 15px;
  font-style: italic;
  line-height: 30px;
  text-align: center;
}
.mbss-text-fader-wrapper.w-slider h4 {
  font-style: normal;
}
.section.parallax {
  background-position: 50% 100% !important;
}
.processwrapper {
  display: block;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 30px 30px 25px 25px;
  background-color: #759194;
}
.process-numbering {
  position: relative;
  z-index: 100;
  width: 60px;
  height: 60px;
  border: 6px solid white;
  border-radius: 100%;
  background-color: #759194;
  font-size: 30px;
  line-height: 41px;
  font-style: italic;
  text-align: center;
}
.process-numbering.process-numbering-6 {
  line-height: 47px;
}
.process-numbering.process-numbering-8 {
  line-height: 48px;
}
.processtext {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  min-height: 48px;
  margin-top: -53px;
  margin-left: 15px;
  padding: 6px 10px 6px 50px;
  float: left;
  border-radius: 15px;
  background-color: white;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: black;
}
.processtext.processtext-step4 {
  font-style: italic;
}
.process-step {
  margin-bottom: 10px;
}
.section.parallax.inner-banner-11 {
  background-image: url("../webflow/images/34985.jpg");
}
.waterSuppliesText h3 {
  text-align: center;
}
.section.parallax.inner-banner-9 {
  background-image: url("site-images/custom/IMG_2729.jpg");
  background-size: cover;
}
.left-big-image2 {
  overflow: hidden;
}
.left-big-image2 .mbss-image {
  display: block;
}
.left-big-image2 img {
  max-width: 100%;
}
.left-service-wrapper h4 {
  margin-top: 0;
}
.manualItem h1,
.manualItem h2 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 44px;
  text-transform: none;
}
.contact-btn {
  float: left;
}
.homeTestimonials .space-in-left {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.homeTestimonials {
  font-size: 13px;
  font-weight: 300;
  line-height: 26px;
}
.homeTestimonials a {
  display: table;
  clear: both;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  text-align: center;
  text-decoration: none;
  -moz-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  margin-top: 20px;
  background-color: #71bbc8;
  color: white;
  margin-left: 0;
}
.homeTestimonials a:hover {
  background-color: transparent;
  box-shadow: #71bbc8 0px -1px 0px 0px inset;
  color: #71bbc8;
}
.homeTestimonials h4 {
  margin-top: 0;
}
.testimonial-title {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}
.testimonial-text {
  font-size: 13px;
  color: #818181;
}
.postSummaryContent {
  max-height: 76px;
  overflow: hidden;
}
.inner-banner.inner-banner-2 {
  background-position: 50% 73%;
}
.signup-btn {
  background-image: url('data:image/gif;base64,R0lGODlhIgAiAOYAAAUuVqnJO6nKPAs1VmOIR6vLNkBoTWWKR1J4S0tyTFd9SiFKUzBYUKbGOHqcQoOmQYOlQIiqQG6SRgozVqnIN0JoTS1WUUFpTVl+STpiT0xzTFh9S32gQ2+TRWiMR4+xPjFaUD5mTydQUpKzPWKHRzVcUA84VihRUhdAVCdQUyxVUS1VURdBVJ+/Op6/OnudQq7NNi5WUVB2TAgxV0VsTRpEVFF4SqLDOZS1PAkzVlF3SkRrTQkyVxM9VafGOEduTaLBOWqORhxFU6nJNomrPxQ+VZO1PBdCVVR6Sn+hQg43VrDPNhhBVSBIU6nJPAYuV6jJO7TTNAYvVwUuV7XUNLXTNLTTMwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAiACIAQAf/gFBVVlZUhYZUhoNVVFVRjYaEjYVVlYNOAoOSj1aak5qMnZqEhJVWUVBOkppSrVNTGxQMr1NPIEMKU1K0pZZOAYqRp6KdxKGHjJOFhU5QpKSJhlGl1M/HnoWp1ImWkYrPj4yj1FVOToikKTceu666T7TtQUAi6IkBzs9U7RUIrgDvXknR8EPXFGiJoDjzxK3huG/FtkVrZgoSlUeNLEk6hG5ZLyrmQE0TFQ0UN3GkPCVTmBHZMkZRpkUxZYrStWksiVnRZQLChx5PWu2qJaXIiAdKXoWKpBAhFYGtAFrw4eBFgxVPssLbBY2RtkWEJhBQkXWo2Xa6ThzIMYqRuVOa/6aFWoRokLCX1aw0o/TR7rJoHC3WLNV02kVFMzt6BBtxZKW9jvgqehgq8SFJ35rNhFYsEtjI4kZCWlROlcWunRyT/CtqJKG3KVcdE0ZXtLdiAgL0YgRDBi2D7eAB2BXUxhK5lBSCLjSFBQ4OA7ZCDTplQBIjTKagvKSqEyKhPDq0aBJQ1wIXEma4SpbICkvXVoK6enKhAA1dOwoYMPjqycXNVATgBCiE6NJKDDpI8cQRBJCAwi4JlDDcK50ZUthorSARQla0wCPdFBlgAE97ojR1DRFCKDihfALV4mINEVRiWWGXxUbTMx5xwp6FglzUGTEXsXfKZoUAqMxb43hk0x9u6CSz0SBf2aOSKJ9FVKQ3IOWjZGQ++sWNd0ulVE4gADs=') /*site-images/custom/mail-icon.gif*/;
  height: 35px;
  width: 35px;
  display: inline-block;
}
#signup-tbx {
  height: 35px;
  line-height: 35px;
}
ul li {
  list-style: outside none none;
  margin-left: 15px;
}
.footer ul li {
  margin-left: 0;
}
.section.parallax.inner-banner-1 {
  background-image: url(/webflow/images/Courtyard-at-Night.jpg);
}
/* Size specific */
.gallerytitle h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.homeslider h1 {
  font-size: 25px;
}
.filter-count {
  opacity: 0.5;
}
.gallerycol {
  max-height: 460px;
}
.gallerycol img {
  height: 100%;
}
.testimonials-wrapper {
  color: #333;
  font-size: 15px;
  /*font-style: italic;*/
  line-height: 30px;
  text-align: center;
}
.testimonials-wrapper h4 {
  color: #fff;
  margin-top: 0;
  text-align: center;
  margin-top: 20px;
}
.banner-wrapper a {
  background-color: #10a1b0;
  border: 1px none #d5a73b;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 20px;
  margin-left: 10px;
  padding: 13px 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
}
.banner-wrapper a:hover {
  background-color: #085158;
  box-shadow: none;
  text-decoration: none;
}
.w-slide {
  text-align: unset;
}
.hero {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
}
/* all devices (tablets, 768px and up) */
@media (min-width: 479px) {
  .homeslider h1 {
    font-size: 49px;
    line-height: 49px;
  }
}
@media (max-width: 479px) {
  .big-title-banner-blue {
    font-size: 38px;
    line-height: 42px;
  }
  .w-icon-slider-left,
  .w-icon-slider-right {
    display: none;
  }
  .sub-text-title {
    letter-spacing: 4px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 1268px) {
  .left-service {
    padding-top: 37px;
    padding-left: 60px;
  }
}
/*     /* Start hidden elements*/
.noUi-value.noUi-value-horizontal.noUi-value-large {
  display: none;
}
.top-text > img:first-child {
  display: none;
}
.w-section.section.parallax {
  display: none!important;
}
#add-to-bag,
.variant-table,
.pricesFrom,
.price-old,
.spec-table,
#Quantity-2,
h2.table-title {
  display: none!important;
}
.w-embed > .section:nth-child(2) {
  display: none;
}
/* End hidden elements */
/* Start custom CSS */
.features-title {
  color: white;
  text-align: left;
  margin-bottom: 15px;
}
.space-in-left > .small-space > .button.button-color {
  display: none;
}
@media screen and (min-width: 1200px) {
  .left-service {
    padding: 13% 13%;
  }
  .w-container {
    max-width: 1170px;
  }
}
/* End custom CSS */
/* Start noUiSlider & Quicksearch CSS */
.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  top: -32px;
  bottom: initial!important;
  min-width: 97px;
}
.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  min-width: 77px;
}
.noUi-value.noUi-value-horizontal.noUi-value-large {
  font-size: 11px;
}
.noUi-tooltip {
  left: 50%;
  transform: translate(-50%, 0);
}
.noUi-connect {
  background: #10a1b0;
}
.noUi-target {
  margin-bottom: 13px;
}
.noUi-handle {
  border: 1px solid #cccccc;
}
.noUi-value-horizontal {
  padding-top: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 8px;
}
.noUi-marker-horizontal.noUi-marker {
  width: 1px;
}
.noUi-marker {
  background: #bbb;
}
.quick-search-checkbox-wrapper {
  min-width: 80px;
}
.quick-search-wrapper .w-col.w-col {
  padding: 0px 5px;
}
@media screen and (max-width: 991px) {
  .noUi-horizontal .noUi-handle-lower .noUi-tooltip,
  .noUi-horizontal .noUi-handle-upper .noUi-tooltip {
    transform: translate(0, 0);
  }
  .noUi-horizontal .noUi-handle-lower .noUi-tooltip {
    left: 0;
  }
  .noUi-horizontal .noUi-handle-upper .noUi-tooltip {
    right: 0;
    left: auto;
  }
}
/* End noUiSlider & Quicksearch CSS*/
/* Start Master Parts CSS */
.w-tab-pane:hover {
  cursor: auto!important;
}
.product-detail-description a {
  text-decoration: underline;
}
.product-detail-description {
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 40px;
}
.filter-heading-icon {
  display: inline-block;
  float: right;
  font-family: 'Corin design', sans-serif;
  font-size: 13px;
}
.filter-text {
  display: inline-block;
}
.product-tab:first-child,
.product-tab:nth-child(4n+0) {
  margin-left: 0px;
}
.product-tab:nth-child(3n+0) {
  margin-right: 0px;
}
.qtybasket.space {
  margin-top: -15px;
}
#filter .filter-dropdown-icon {
  display: none;
}
.filter-subdropdown-list-2 {
  padding-left: 15px;
}
#filter .cat-selected {
  font-weight: bold;
}
.w-tab-pane:hover {
  cursor: zoom-in;
}
.w-col .w-col {
  padding-left: 10px;
  padding-right: 10px;
}
.select.quantity {
  width: 70px;
  height: 45px;
  margin-right: 10px;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f0f0f0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC");
  background-position: 85% 50%;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  border-radius: 0;
  float: left;
}
.filter-subdropdown-list {
  position: relative;
  display: block;
  background-color: #fff;
}
.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
}
.filter-subdropdown-link {
  margin-bottom: 0px;
  padding: 7px 10px 7px 40px;
  text-decoration: none;
}
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: normal;
  float: left;
}
.productwrapper .new {
  z-index: 1;
}
.product-detail-code {
  display: none;
}
.section {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f7f7f7;
}
.green-btn {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 13px 20px 12px;
  border-radius: 0px;
  background-color: #10a1b0;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  color: #fff;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  float: left;
}
.green-btn:hover {
  background-color: #666;
  color: #fff;
}
.green-btn.product-btn {
  width: auto;
  padding-left: 0px;
}
.green-btn.space:hover {
  text-decoration: none;
}
.green-btn.no-margin {
  margin-right: 0px;
  margin-bottom: 0px;
}
.select {
  height: 48px;
  border: 1px solid #dedfde;
  border-radius: 0px;
  background-color: #dedede;
  color: #9e9e9e;
}
.select.sort {
  width: auto;
  height: 38px;
  margin: -7px 7px 0px;
  float: left;
}
.checkboxlabel {
  float: none;
  font-size: 16px;
}
.checkbox {
  margin-top: 4px;
  margin-right: 7px;
  float: left;
}
.table {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #dedfde;
}
.table.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}
.fontello {
  top: 0px;
  right: 0px;
  margin-right: 7px;
  font-family: 'Corin design', sans-serif;
  font-weight: 400;
}
.fontello.fontello-addtobasket {
  margin-left: -20px;
  padding: 10px;
}
.fontello.iconheader {
  position: relative;
  margin-top: -1px;
  padding-right: 5px;
  font-family: 'Corin design', sans-serif;
}
.fontello.addtobasket {
  position: relative;
  top: -1px;
  font-size: 15px;
}
.fontello.video {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  color: #fff;
}
.elementwrapper:hover {
  text-decoration: none;
}
.producttablebody {
  padding: 6px 10px;
  border-top: 1px solid #dedfde;
}
.greyrow {
  background-color: #dedede;
}
.tabmenu {
  width: 100%;
  float: none;
  clear: both;
  border-bottom: 1px solid #dedede;
  text-align: center;
}
.product-tab {
  display: inline-block;
  width: 31.33%;
  margin-top: 20px;
  margin-right: 1.5%;
  margin-bottom: 0px;
  margin-left: 1.5%;
  padding: 1px;
  float: left;
  background-color: #f0f0f0;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  text-align: center;
}
.product-tab:hover {
  background-color: #10a1b0;
  color: #fff;
}
.product-tab.w--current {
  background-color: #10a1b0;
  color: #fff;
}
.product-tab img {
  background-color: white;
}
.product-tab p {
  padding: 10px;
  margin-bottom: 0px;
}
.social {
  display: inline-block;
  margin-right: 5px;
  float: none;
}
.social.fb {
  top: -6px;
  margin-left: 3px;
}
.social.googleplus {
  top: 4px;
  margin-left: 0px;
}
.socialwrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}
.product-pane {
  margin-right: 3px;
  margin-left: 3px;
  border: 1px none #dedfde;
}
.productwrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  -webkit-transition: color 500ms ease, opacity 500ms ease;
  transition: color 500ms ease, opacity 500ms ease;
  font-size: 17px;
  text-decoration: none;
  display: block;
  float: left;
  border-bottom: 2px solid #ebe8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  text-align: center;
  letter-spacing: -0.3px;
  min-height: 363px;
}
@media (max-width: 767px) {
  .productwrapper {
    min-height: 320px;
  }
}
@media (max-width: 479px) {
  .productwrapper {
    min-height: 270px;
  }
}
.productwrapper img {
  margin-bottom: 5px;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  min-height: 0px;
}
.productcontent img {
  min-width: 100%;
}
.productwrapper:hover {
  text-align: center;
  opacity: 1;
  color: #10a1b0;
  text-decoration: underline;
}
.list-view-select option:hover {
  background-color: #10a1b0;
}
.productwrapper.border {
  min-height: 315px;
  border: 1px solid #dedfde;
}
.product-image {
  min-width: 100%;
}
.product-name {
  margin-top: 10px;
  margin-bottom: 6px;
  text-align: center;
  padding: 0px 8px;
  font-size: 13px;
  line-height: 26px;
  font-weight: 300;
  color: black;
}
.product-price {
  margin-top: 0px;
  color: #10a1b0;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
}
.tabcontent {
  padding-top: 40px;
  padding-bottom: 40px;
}
.tab {
  padding: 14px 20px;
  float: left;
  background-color: #666;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  color: #fff;
  text-transform: uppercase;
}
.tab:hover {
  color: #fff;
  background-color: #10a1b0;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}
.tab.w--current {
  background-color: #10a1b0;
  color: #fff;
}
.video-btn {
  position: absolute;
  left: 0px;
  right: 0px;
  display: inline-block;
  width: 44px;
  height: 44px;
  margin: 35% auto;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #10a1b0;
  font-size: 19px;
  text-align: center;
}
.standard-price {
  color: #10a1b0;
  font-weight: 600;
  text-decoration: line-through;
}
.sale-price {
  display: inline-block;
  color: #10a1b0;
  font-weight: 600;
}
.productpricewrapper {
  margin-bottom: 20px;
  float: none;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.1px;
}
.producth1 {
  padding-bottom: 10px;
  border-bottom: 1px solid #dedfde;
}
.tabs {
  margin-bottom: 10px;
  text-align: center;
}
.btn {
  display: inline-block;
  width: 49%;
  padding: 20px 10px;
  background-color: #4aa6dd;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #fff;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
}
.btn:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}
.button._2 {
  width: auto;
  float: none;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}
.button._2:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #4aa6dd;
}
.new {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #10a1b0;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  color: #fff;
  line-height: 50px;
}
.formwrapper {
  position: relative;
  margin-bottom: 0px;
}
.table-title {
  border: none;
}
.text-align-right {
  text-align: right;
}
.space {
  margin-top: 30px;
}
.border-bottom {
  border-bottom: 1px solid #dedfde;
}
.small-space {
  margin-top: 10px;
}
.pagination {
  margin-top: 20px;
  text-align: right;
}
.pageno {
  margin-right: 7px;
  margin-bottom: 7px;
  padding-right: 9px;
  padding-left: 9px;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #666;
}
.pageno:hover {
  background-color: transparent;
  color: #10a1b0;
}
.pageno.w--current {
  display: inline-block;
  color: #10a1b0;
  text-decoration: underline;
}
.filter-select-wrapper {
  margin-top: 10px;
}
.filter-select-wrapper.space {
  padding-top: 20px;
}
.pagination-text {
  display: inline-block;
  margin-right: 9px;
}
.hidden {
  display: none;
}
.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}
.tint {
  background-color: #f0f0f0;
}
.tint.padding {
  min-height: 590px;
  padding: 30px;
}
.original-price {
  display: inline-block;
  color: #10a1b0;
  font-weight: 600;
  text-decoration: line-through;
}
.price-old {
  color: #10a1b0;
  text-decoration: line-through;
}
.minus-space {
  margin-top: -20px;
}
.no-margin-top {
  margin-top: 0px;
}
.larger {
  font-size: 35px;
}
.breadcrumb {
  margin-top: -20px;
  margin-bottom: 20px;
  padding-left: 0px;
}
.video-img {
  position: relative;
}
.productcontent {
  border: 1px solid #dedfde;
  background-color: white;
}
.w-slider {
  height: auto;
}
.hide {
  display: block;
}
.stock {
  margin-bottom: 20px;
}
.spec-table {
  margin-bottom: 40px;
}
.spec-table table {
  width: 100%;
}
.spec-table td {
  width: 50%;
}
td {
  border: 1px solid #e7e7e7;
  padding: 5px 10px;
}
.sort-filter-wrapper {
  float: left;
}
.results-filter-wrapper {
  float: right;
}
.brand-filter {
  display: none;
}
.brand-filter-img {
  float: right;
  width: 85px;
}
.brand-filter-text {
  display: none;
}
.border {
  border: 1px solid #dedfde;
}
.scroll-table {
  display: none;
  overflow: auto;
  margin-bottom: 30px;
}
.product-table {
  display: block;
  background-color: transparent;
}
.m-filter-wrapper {
  display: block;
  opacity: 1;
  margin-bottom: 20px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border: 1px solid #ddd;
  background-color: #eee;
  color: #666;
  font-weight: 400;
}
.filter-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 15px 20px;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}
.filter-heading.mobile {
  display: none;
}
.dropdown-toggle {
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #eee;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  color: #666;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
}
.filter-dropdown {
  display: block;
}
.filter-dropdown-list {
  position: relative;
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fff;
  color: #333;
}
.filter-dropdown-list.w--open {
  position: relative;
}
.filter-link {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
}
.filter-subdropdown-list {
  position: relative;
  display: block;
  background-color: #fff;
}
.filter-element-wrapper {
  display: block;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}
.clear-btn {
  margin: 15px 20px;
  display: inline-block;
  padding: 13px 17px;
  border: 1px none #d5a73b;
  background-color: #10a1b0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.clear-btn:hover {
  color: #fff;
  background-color: #085158;
  box-shadow: none;
  text-decoration: none;
}
.subdropdown-toggle {
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
}
.product-list-head {
  border-bottom: 1px solid #eee;
}
.product-list-header-img {
  margin-bottom: 40px;
}
.product-list-header-text {
  margin-bottom: 40px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 26px;
  font-weight: 300;
}
.product-list-head h2,
.product-list-head h1 {
  text-align: center;
}
.dropdown-icon {
  margin-right: 14px;
}
.product-detail-bottom-tabs {
  margin-top: 60px;
}
.product-detail-price {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #10a1b0;
  font-size: 22px;
  line-height: 26px;
}
.description {
  margin-bottom: 15px;
}
.product-detail-description {
  margin-top: 20px;
}
.variant-table {
  padding-top: 20px;
  border-bottom: 1px solid #eee;
}
.product-detail-slider {
  display: none;
  text-align: center;
  height: auto;
}
.product-detail-slide {
  border-bottom: 2px solid #ebe8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
}
@media (max-width: 991px) {
  .tabmenu {
    width: 100%;
    float: none;
    text-align: center;
    margin-left: 10px;
  }
  .tabs {
    display: block;
  }
  .m-filter-wrapper {
    margin-left: -10px;
  }
}
@media (max-width: 767px) {
  .w-col-9 .w-col-small-6 {
    padding: 0px 10px;
  }
  .product-detail-slider {
    display: block;
    margin-bottom: 10px;
  }
  .tabs {
    display: none;
  }
  .hide {
    display: none;
    background-color: #fff;
  }
  .m-filter-wrapper {
    margin-bottom: 50px;
    display: none;
  }
  .filter-select-wrapper {
    margin-bottom: 13px;
  }
  .results-filter-wrapper {
    display: none;
  }
  .filter-heading {
    display: none;
  }
  .filter-heading.mobile {
    display: block;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid #eee;
    margin-left: -10px;
  }
  .product-detail-slide .productwrapper {
    border-bottom: 0px ;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .w-col .w-col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
/* Finish Master Parts CSS*/
h1,
h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #10a1b0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
h1 {
  margin-top: 0px;
}
.range-slider-2
{
    display:none;
}
.w-col.w-col-3.w-col-stack.column-align-right.button a {
color:#fff;
text-align:unset;
}
.w-col.w-col-3.w-col-stack.column-align-right.button {
text-align:center;
}