body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #085158;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #10a1b0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h4 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #10a1b0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 26px;
  font-weight: 300;
}

a {
  color: #323232;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}

.button {
  display: inline-block;
  padding: 13px 17px;
  border: 1px none #d5a73b;
  background-color: #10a1b0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #085158;
  box-shadow: none;
  text-decoration: none;
}

.button.button-color {
  margin-left: 0px;
  border-color: #d5a73b;
  background-color: #10a1b0;
  color: #fff;
}

.button.button-color:hover {
  border-bottom-color: #085158;
  background-color: #085158;
}

.button.button-color.float-right {
  float: right;
}

.button.button-color.float-right:hover {
  box-shadow: inset 0 -1px 0 0 #71bbc8;
  color: #71bbc8;
}

.button.button-color.maintenance {
  background-color: #d0e15f;
  color: #fff;
}

.button.button-color.maintenance:hover {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 0 #d0e15f;
  color: #d0e15f;
}

.button.button-color.lighting {
  background-color: #ecf2bd;
  color: #000;
}

.button.button-color.lighting:hover {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 0 #e5ebb7;
  color: #dce69c;
}

.button.maintenance {
  color: #d0e15f;
}

.button.maintenance:hover {
  color: #fff;
}

.button.inline {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.button.inline:hover {
  border-style: none;
  border-color: #fff;
  background-color: #063135;
}

.header {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  border-bottom-color: #aaa;
  background-color: #10a1b0;
  background-image: none;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, .09);
}

.header.bottom {
  text-align: center;
}

.navigation-link {
  display: inline-block;
  padding: 58px 23px;
  border-right: 1px none rgba(0, 0, 0, .11);
  border-left: 1px none rgba(0, 0, 0, .11);
  -webkit-transition: color 300ms ease-in-out, opacity 300ms ease, background-color 300ms ease;
  transition: color 300ms ease-in-out, opacity 300ms ease, background-color 300ms ease;
  color: hsla(0, 0%, 100%, .7);
  line-height: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.navigation-link:hover {
  background-color: transparent;
  color: #fff;
  text-decoration: underline;
}

.navigation-link.w--current {
  color: #fff;
}

.navigation-link.dropdown {
  padding-right: 40px;
}

.navigation-link._2 {
  padding-top: 53px;
  padding-bottom: 48px;
}

.brand {
  z-index: 1000;
  max-width: 171px;
  padding-top: 13px;
  padding-bottom: 17px;
  padding-left: 15px;
  opacity: 1;
  -webkit-transition: opacity 300ms ease, background-color 300ms ease;
  transition: opacity 300ms ease, background-color 300ms ease;
  color: #fff;
  text-transform: uppercase;
}

.brand:hover {
  opacity: 1;
}

.banner {
  position: relative;
  overflow: hidden;
  background-color: #474747;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/3.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/3.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.section {
  padding: 80px 10px;
}

.section.parallax {
  background-color: #bfdee3;
  text-transform: capitalize;
}

.section.parallax.inner-banner-7 {
  background-image: url('../images/DSC_0831-2.jpg');
  background-position: 50% 173%;
}

.section.parallax.inner-banner-9 {
  background-image: url('../images/IMG_2729.jpg');
  background-position: 50% 50%;
  background-size: auto;
}

.section.parallax.inner-banner-11 {
  background-image: url('../images/34985.jpg');
  background-position: 50% 100%;
  background-size: auto;
}

.section.parallax.inner-banner-1 {
  background-image: url('../images/34965.jpg');
  background-position: 50% 100%;
}

.section.lastsection {
  padding-bottom: 160px;
}

.section.hide {
  display: block;
}

.section.no-padding {
  padding-bottom: 0px;
}

.top-text {
  text-align: center;
}

.top-text.developer {
  margin-bottom: 90px;
}

.space {
  margin-top: 50px;
}

.service-wrapper {
  margin-bottom: 50px;
}

.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.left-big-image {
  overflow: hidden;
  height: 100%;
  min-height: 850px;
  background-image: url('../images/5_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.left-service {
  overflow: hidden;
  min-height: 850px;
  padding: 12%;
  background-color: #f0f0f0;
}

.small-left-services {
  margin-top: 30px;
}

.left-icon-services {
  width: 60px;
  height: 60px;
  margin-right: 30px;
  float: left;
  border-radius: 100%;
  background-color: #10a1b0;
}

.left-icon-services.icon-1 {
  background-image: url('../images/star.png');
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}

.left-icon-services.icon-2 {
  background-image: url('../images/star.png');
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}

.left-service-wrapper {
  overflow: hidden;
}

.no-space {
  margin-top: 0px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  text-decoration: none;
}

.no-space:hover {
  text-decoration: none;
}

.no-space.manuallink {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 0px;
}

.paragraph-ttile {
  font-size: 15px;
  line-height: 28px;
  font-weight: 300;
}

.paragraph-ttile.blog-text {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  text-align: left;
}

.hero {
  padding-top: 180px;
  padding-bottom: 180px;
  background-color: #4c768c;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/1_3.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('../images/1_3.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  color: #fff;
  font-size: 44px;
  line-height: 52px;
  font-weight: 200;
}

.column-align-right {
  text-align: right;
}

.space-in-left {
  overflow: hidden;
  width: 50%;
  height: 300px;
  max-height: 300px;
  padding: 60px 40px;
  float: left;
  background-color: #eee;
}

.space-in-left.photo {
  background-image: url('../images/2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.space-in-left.photo._2 {
  background-image: url('../images/4_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.space-in-left.photo._3 {
  background-image: url('../images/3.jpg');
  background-position: 50% 66%;
}

.navigation-dot {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 20px;
  opacity: 0.55;
  font-size: 11px;
}

.slider {
  height: auto;
  background-color: transparent;
}

.small-space {
  margin-top: 20px;
}

.small-space.more-space {
  margin-top: 40px;
}

.footer {
  position: relative;
  padding-top: 60px;
  padding-bottom: 160px;
  background-color: #085158;
  color: #fff;
}

.features-title {
  margin-top: 0px;
  margin-bottom: -10px;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.paragraph-lighter {
  margin-bottom: 0px;
  color: hsla(0, 0%, 100%, .7);
  font-weight: 300;
}

.image-in-footer {
  overflow: hidden;
  height: 140px;
  max-height: 300px;
}

.text-field {
  height: 44px;
  color: #919191;
}

.form-message {
  background-color: #10a1b0;
  color: #fff;
  font-size: 15px;
  line-height: 28px;
}

.error-message {
  background-color: #ff6e6e;
}

.social-footer {
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-top: 8px;
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: transparent;
  background-image: url('../images/iconmonstr-google-plus-icon-256.png');
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  opacity: 0.7;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.social-footer:hover {
  border-color: #10a1b0;
  background-color: #10a1b0;
  opacity: 1;
}

.social-footer.twiter {
  background-image: url('../images/twitter.svg');
  background-size: 16px;
}

.social-footer.rss {
  background-image: url('../images/prime-location.png');
  background-size: 20px;
}

.social-footer.facebook {
  background-image: url('../images/instagram-icon.png');
  background-size: 128px;
}

.social-footer.instagram {
  background-image: url('../images/zoopla.png');
  background-size: 20px;
}

.social-footer.pinterest {
  background-image: url('../images/rightmove.png');
  background-size: 20px;
}

.small-bottom-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 25px;
  padding-bottom: 20px;
  background-color: #10a1b0;
}

.center {
  text-align: center;
}

.inner-banner {
  position: relative;
  height: 150px;
  background-color: #085158;
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
}

.inner-banner.inner-banner-2 {
  background-image: url('../images/Bupa007--1034.jpg');
  background-position: 50% 73%;
}

.inner-banner.inner-banner-3 {
  background-image: url('../images/DSCF1540.jpg');
  background-position: 50% 453%;
  background-size: auto;
}

.inner-banner.inner-banner-4 {
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.inner-banner.inner-banner-5 {
  background-image: url('../images/DSC_0010.jpg');
  background-position: 50% 50%;
}

.inner-banner.inner-banner-6 {
  margin-top: 0px;
  background-image: none;
  background-size: auto;
  text-transform: uppercase;
}

.inner-banner.inner-banner-7 {
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-attachment: scroll;
}

.inner-banner.inner-banner-8 {
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.inner-banner.inner-banner-9 {
  background-image: url('../images/13.jpg');
  background-position: 50% 47%;
}

.inner-banner.inner-banner-10 {
  background-image: none;
  background-size: auto;
}

.inner-banner.inner-banner-11 {
  background-image: url('../images/DSC_0024_edited-1.jpg');
  background-position: 50% 0px;
}

.inner-banner.inner-banner-12 {
  background-image: url('../images/060308-065-retouch.jpg');
  background-position: 50% 7px;
}

.inner-banner.inner-banner-13 {
  background-image: url('../images/Bupa009--1036.jpg');
}

.inner-banner.inner-banner-14 {
  background-image: url('../images/DSC_0023.jpg');
}

.arrow-pattern {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  display: none;
  height: 23px;
  background-image: url('../images/triangle.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  opacity: 1;
}

.center-sub-text {
  position: relative;
  top: 78px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  font-style: normal;
  text-align: center;
}

.sub-title {
  display: inline-block;
  padding: 12px 22px;
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  font-size: 26px;
}

.sub-title.maintenance {
  background-color: #d0e15f;
  color: #fff;
}

.sub-title.lighting {
  background-color: #f4f8d3;
  color: #000;
}

.sub-title.irrigation {
  color: #fff;
}

.white {
  color: #fff;
}

.testimonials-wrapper {
  margin-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
}

.testimonials-paragraph {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  font-style: italic;
  text-align: center;
}

.testimonials-name {
  margin-top: 0px;
  color: #fff;
  text-align: center;
}

.contact-icon {
  display: block;
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-bottom: 17px;
  margin-left: auto;
  border-bottom: 2px solid #10a1b0;
  background-image: url('../images/icon4.png');
  background-position: 50% 50%;
  background-size: 50px;
  background-repeat: no-repeat;
}

.contact-icon.icon-2 {
  background-image: url('../images/icon3.png');
  background-size: 50px;
}

.text-area {
  padding-bottom: 104px;
}

.center-container {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  margin-top: 0px;
  padding: 70px 100px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: hsla(0, 0%, 100%, .8);
}

.banner-text-large {
  margin-bottom: 0px;
  color: #fff;
  font-size: 50px;
  line-height: 65px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.banner-text-small {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 3px;
}

.navigation-menu {
  border-right: 1px none rgba(0, 0, 0, .15);
}

.navigation-menu.bottom {
  display: block;
  margin-right: auto;
  margin-left: auto;
  float: none;
  text-align: center;
}

.ipad-space {
  padding-left: 0px;
}

.email {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: hsla(0, 0%, 100%, .7);
  text-decoration: underline;
}

.email:hover {
  color: #fff;
  text-decoration: underline;
}

.email.white {
  color: #fff;
  text-decoration: underline;
}

.blog-img {
  width: 100%;
}

.socialmedia {
  display: inline-block;
  overflow: visible;
  margin-top: 20px;
  margin-right: 20px;
}

.socialmedia.facebook {
  margin-right: 16px;
}

.socialmedia.googleplus {
  margin-top: -1px;
}

.footer-img {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 27px;
}

.homeslider {
  height: auto;
}

.homeslider.banneroverlay {
  width: 100%;
  height: 72%;
  background-color: #fff;
}

.slidernav {
  bottom: 20px;
  display: none;
}

.dropdownlist.w--open {
  background-color: rgba(0, 0, 0, .7);
}

.dropdownlink {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid hsla(0, 0%, 100%, .15);
  -webkit-transition: background-color 300ms ease, opacity 300ms ease;
  transition: background-color 300ms ease, opacity 300ms ease;
  color: hsla(0, 0%, 100%, .7);
  font-weight: 300;
  letter-spacing: 1px;
}

.dropdownlink:hover {
  background-color: rgba(16, 161, 176, .9);
  color: #fff;
  text-decoration: underline;
}

.dropdownlink.w--current {
  color: #fff;
  text-decoration: underline;
}

.processsection {
  display: none;
}

.bottomnav {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  margin-top: 60px;
  margin-bottom: -35px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #3b3b3b;
}

.footer-navigation-link {
  color: #fff;
}

.footer-navigation-link:hover {
  color: #71bbc8;
}

.footer-navigation-link.w--current {
  color: #71bbc8;
}

.tabs {
  display: block;
  margin-bottom: 20px;
  background-color: transparent;
}

.tabmenu {
  padding-right: 7px;
  padding-left: 7px;
}

.tablink {
  width: 16.667%;
  padding: 14px 7px;
  background-color: transparent;
}

.tablink.w--current {
  background-color: transparent;
}

.tabcontent {
  padding-right: 14px;
  padding-left: 14px;
}

.tabcontent-img {
  width: 100%;
}

.gallerycol {
  padding-right: 0px;
  padding-left: 0px;
}

.dropdown.hide {
  display: none;
}

.smalltext {
  font-size: 16px;
}

.accordian {
  width: 100%;
}

.accordianlist {
  position: relative;
}

.accordianlist.w--open {
  opacity: 0;
}

.accordiancontent {
  width: 100%;
  padding: 20px;
}

.accordiantoggle {
  display: block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  background-color: #71bbc8;
  -webkit-transition: height 500ms ease;
  transition: height 500ms ease;
  color: #fff;
  font-size: 17px;
}

.accordiantoggle.maintenance {
  background-color: #d0e15f;
}

.accordiantoggle.lighting {
  background-color: #f4f8d3;
  color: #333;
}

.faqwrapper {
  margin-top: 60px;
}

.hide {
  display: none;
}

.row {
  display: block;
}

.gallerytitle {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-top: -50px;
  margin-bottom: 0px;
  padding: 10px 12px;
  background-color: hsla(0, 0%, 100%, .66);
  color: #333;
  font-size: 20px;
  text-align: center;
}

.gallerytitle:hover {
  text-decoration: none;
}

.devlink {
  color: #71bbc8;
}

.overlaywrapper {
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 5px;
  background-color: #085158;
}

.img-overlay {
  overflow: hidden;
  margin-bottom: -5px;
  color: rgba(0, 0, 0, .66);
}

.img-overlay:hover {
  color: #000;
  text-decoration: none;
}

.hide-mobile {
  display: none;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}

.hide-mobile:hover {
  display: none;
}

.image1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../images/5_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.image2 {
  width: 100%;
  height: 100%;
  background-image: url('../images/4_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.begin-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 45px;
  z-index: 20;
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  text-align: center;
}

.button-wrapper {
  margin-top: 40px;
  text-align: center;
}

.image3 {
  width: 100%;
  height: 100%;
  background-image: url('../images/1_3.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.image4 {
  width: 100%;
  height: 100%;
  background-image: url('../images/2_3.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.image5 {
  width: 100%;
  height: 100%;
  background-image: url('../images/4.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-link {
  display: block;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: hsla(0, 0%, 100%, .7);
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  text-decoration: underline;
}

.footer-link:hover {
  color: #fff;
}

.more-space {
  margin-top: 90px;
}

.search-wrapper {
  position: relative;
  display: none;
  max-width: 500px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.topnav {
  display: none;
  padding-top: 10px;
  background-color: #085158;
}

.quick-search-wrapper {
  margin-bottom: 0px;
  padding-top: 55px;
  padding-bottom: 30px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #ddd;
}

.quick-search-radio-field {
  display: block;
  text-align: left;
}

.range-slider {
  position: relative;
  margin-top: -15px;
  padding-right: 11%;
  padding-left: 11%;
  text-align: center;
}

.range-slider-label-1 {
  display: inline-block;
  margin-bottom: 0px;
  margin-left: -10px;
  float: left;
}

.range-slider-label-2 {
  display: inline-block;
  margin-right: -10px;
  margin-bottom: 0px;
  float: right;
}

.range-slider-label-wrapper {
  position: relative;
}

.quick-search-btn {
  padding: 13px 17px;
  float: right;
  background-color: #10a1b0;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}

.quick-search-btn:hover {
  background-color: #085158;
}

.range-slider-2 {
  position: relative;
  display: block;
  margin-top: -15px;
  padding-right: 11%;
  padding-left: 11%;
  text-align: center;
}

.quick-search-radio-wrapper {
  min-width: 80px;
  float: right;
}

.banner-text-medium {
  font-size: 26px;
  line-height: 34px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 3px;
}

html.w-mod-js *[data-ix="fade-in-big-title-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, -79px) scale(0.5, 0.5);
  -ms-transform: translate(0px, -79px) scale(0.5, 0.5);
  transform: translate(0px, -79px) scale(0.5, 0.5);
}

html.w-mod-js *[data-ix="fade-in-sub-text-title-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, 79px) scale(0.5, 0.5);
  -ms-transform: translate(0px, 79px) scale(0.5, 0.5);
  transform: translate(0px, 79px) scale(0.5, 0.5);
}

html.w-mod-js *[data-ix="accordian-list"] {
  display: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .navigation-link {
    display: block;
    max-width: 100%;
    margin-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid hsla(0, 0%, 100%, .15);
    color: #fff;
  }
  .navigation-link:hover {
    background-color: #10a1b0;
    color: #fff;
  }
  .navigation-link.w--current {
    text-decoration: underline;
  }
  .navigation-link.dropdown {
    max-width: 100%;
  }
  .navigation-link.dropdown.w--open {
    margin-bottom: -14px;
  }
  .left-big-image {
    min-height: 500px;
  }
  .left-service {
    height: auto;
    min-height: 0px;
    padding: 55px 50px 75px;
  }
  .paragraph-ttile {
    padding-right: 50px;
    padding-left: 50px;
  }
  .hero {
    text-align: center;
  }
  .column-align-right {
    text-align: center;
  }
  .footer {
    padding-top: 30px;
  }
  .features-title {
    margin-top: 30px;
  }
  .inner-banner {
    background-size: 1000px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% 195%;
    background-size: 990px;
  }
  .inner-banner.inner-banner-3 {
    background-position: 50% 0px;
    background-size: 1000px;
  }
  .inner-banner.inner-banner-5 {
    background-position: 50% 0px;
    background-size: 1000px;
  }
  .inner-banner.inner-banner-8 {
    background-size: 990px;
  }
  .inner-banner.inner-banner-10 {
    background-position: 50% -108px;
  }
  .inner-banner.inner-banner-13 {
    background-position: 100% 0%;
  }
  .center-container {
    padding-right: 50px;
    padding-left: 50px;
  }
  .navigation-menu {
    padding-right: 0px;
    padding-left: 0px;
    background-color: rgba(0, 0, 0, .7);
  }
  .navigation-menu.bottom {
    background-color: transparent;
  }
  .hamburger {
    margin-top: 29px;
    background-color: #10a1b0;
    color: #fff;
  }
  .hamburger.w--open {
    background-color: #085158;
  }
  .ipad-space {
    padding-left: 0px;
  }
  .dropdownlist.w--open {
    margin-top: 13px;
  }
  .dropdownlink {
    padding: 10px 40px;
    background-color: transparent;
  }
  .dropdown {
    max-width: 100%;
    background-color: transparent;
  }
  .quick-search-wrapper {
    text-align: center;
  }
  .quick-search-radio-field {
    position: relative;
    display: inline-block;
    min-width: 75px;
    margin-left: 20px;
  }
  .range-slider {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .quick-search-btn {
    float: none;
  }
  .range-slider-2 {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .quick-search-radio-wrapper {
    margin-top: -10px;
    margin-bottom: 30px;
    float: none;
  }
}

@media (max-width: 767px) {
  .navigation-link {
    display: block;
    clear: both;
  }
  .brand {
    max-width: 140px;
  }
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .service-wrapper {
    margin-bottom: 40px;
  }
  .left-big-image {
    min-height: 400px;
  }
  .left-service {
    padding-top: 50px;
    padding-right: 22px;
    padding-left: 22px;
  }
  .left-icon-services {
    margin-right: 6%;
  }
  .paragraph-ttile {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 13px;
  }
  .hero {
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 30px;
    line-height: 35px;
  }
  .space-in-left {
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .space-in-left.photo {
    width: 100%;
    height: 300px;
  }
  .footer {
    padding: 0px 15px 140px;
  }
  .inner-banner {
    height: 100px;
    background-size: 850px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% 1305%;
    background-size: 850px;
  }
  .inner-banner.inner-banner-3 {
    background-size: 760px;
  }
  .inner-banner.inner-banner-5 {
    background-size: 800px;
  }
  .inner-banner.inner-banner-8 {
    background-size: 880px;
  }
  .inner-banner.inner-banner-13 {
    background-position: 100% 0%;
  }
  .arrow-pattern {
    display: none;
  }
  .center-sub-text {
    top: 50px;
  }
  .center-container {
    left: 10px;
    right: 10px;
    padding: 50px 30px;
  }
  .banner-text-large {
    font-size: 28px;
    line-height: 35px;
  }
  .banner-text-small {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1px;
  }
  .hamburger {
    margin-top: 20px;
    margin-right: 10px;
  }
  .hamburger.w--open {
    margin-right: 10px;
  }
  .ipad-space {
    margin-bottom: 30px;
  }
  .homeslider.banneroverlay {
    height: 470px;
  }
  .dropdownlist.w--open {
    margin-top: 13px;
  }
  .processsection {
    padding-right: 10px;
    padding-left: 10px;
  }
  .footer-navigation-link {
    padding-right: 13px;
    padding-left: 13px;
  }
  .hide {
    display: none;
  }
  .hide-mobile {
    display: none;
  }
  .begin-wrapper {
    display: none;
  }
  .button-wrapper {
    margin-top: 20px;
  }
  .more-space {
    margin-top: 50px;
  }
  .search-wrapper {
    max-width: 100%;
  }
  .topnav {
    padding-right: 10px;
    padding-left: 10px;
  }
  .banner-text-medium {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
  }
}

@media (max-width: 479px) {
  .brand {
    font-size: 21px;
  }
  .brand.w--current {
    font-size: 21px;
  }
  .service-wrapper {
    margin-bottom: 60px;
  }
  .left-service {
    padding-bottom: 30px;
  }
  .column-align-right {
    margin-top: 15px;
  }
  .space-in-left {
    max-height: 10000px;
  }
  .inner-banner {
    background-size: 690px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% -385px;
  }
  .inner-banner.inner-banner-3 {
    background-size: 590px;
  }
  .inner-banner.inner-banner-5 {
    background-size: 720px;
  }
  .inner-banner.inner-banner-12 {
    background-size: 830px;
  }
  .testimonials-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .center-container {
    padding: 40px 20px;
  }
  .banner-text-large {
    font-size: 23px;
    line-height: 33px;
    letter-spacing: 2px;
  }
  .homeslider {
    height: auto;
  }
  .footer-navigation-link {
    display: block;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 13px;
    text-align: center;
  }
}

@font-face {
  font-family: 'Corin design';
  src: url('../fonts/corin-design.eot') format('embedded-opentype'), url('../fonts/corin-design.woff') format('woff'), url('../fonts/corin-design.ttf') format('truetype'), url('../fonts/corin-design.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}