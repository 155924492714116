/* Global Settings for Core */
/* Import navigation core */
/* MAIN NAVIGATION */
ul#main-nav.navigation.justify {
  text-align: justify;
  min-width: 500px;
}
ul#main-nav.navigation.justify:after {
  content: '';
  display: inline-block;
  width: 100%;
}
ul#main-nav.navigation.justify > li {
  display: table-cell;
  width: 1%;
  float: none;
}
ul#main-nav.navigation > li {
  text-align: center;
  float: left;
}
ul#main-nav.navigation > li > ul li {
  text-align: left;
}
ul#main-nav.navigation li {
  list-style: none;
}
ul#main-nav.navigation > li > ul {
  position: absolute;
  z-index: 9999;
}
ul#main-nav.navigation .nav-col {
  float: left;
}
/* COMMON */
ul.navigation .arrow.arrow-up {
  display: none;
}
ul.navigation .dropdown-nav {
  display: none;
}
/* MOBILE LEFT NAVIGATION */
ul#mobile-left {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
/* MOBILE TOP NAVIGATION */
ul#mobile-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
a:focus {
  outline: none;
}
/* bootsrap overrides*/
.breadcrumb {
  margin-bottom: 0px;
}
ul {
  padding: 0px;
  margin: 0px;
}
ul li {
  margin-left: 0;
  list-style: none;
}
/* BANNER LAYERS RESPONSIVE */
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  font-size: 15px;
}
.banner-drop-options {
  display: none;
}
/* add fade class to carousel to make transitions fade through rather than scroll */
.carousel.banner-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel.banner-fade .carousel-inner .active {
  opacity: 1;
}
.carousel.banner-fade .carousel-inner .active.left,
.carousel.banner-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel.banner-fade .carousel-inner .next.left,
.carousel.banner-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel.banner-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
/* Mobile First */
.the-website {
  position: relative;
}
#scrollUp {
  background-image: url(site-images/master/scrollTop.jpg);
  bottom: 10px;
  color: #FFFFFF;
  height: 38px;
  width: 38px;
  right: 10px;
  opacity: 0.8;
}
#scrollUp.move-up-cookie {
  bottom: 30px;
}
.mobile-menu-trigger span {
  font-size: 20px;
  cursor: pointer;
}
#footer-wrapper {
  margin-top: 20px;
  margin-bottom: 50px;
}
#bag {
  position: relative;
}
#bag li {
  list-style: none;
}
#order-drop-wrapper {
  position: absolute;
}
#search {
  display: none;
}
img.not-selected {
  display: none;
}
img.thumb-img {
  cursor: pointer;
}
.product-main-img-holder {
  height: 600px;
}
#ytPop {
  background-color: #000;
  width: 600px;
  height: 450px;
  position: relative;
}
#videoList li {
  text-align: left;
  width: 120px;
  height: 90px;
}
#videoList li:hover {
  opacity: 0.9;
}
#videoList li .video-thumb {
  position: absolute;
}
#videoList li .ytPopBtn {
  position: relative;
}
#videoList li .vidPlayBtn {
  position: absolute;
  left: 20px;
  top: 20px;
}
.qty-tbx {
  max-width: 50px;
  text-align: center;
}
.country-dropdown {
  width: 150px;
}
#info ul,
.postContent ul {
  margin-left: 14px;
}
#info li,
.postContent ul li {
  list-style: disc;
}
.new-icon {
  position: absolute;
}
.upper-case,
.text-uppercase {
  text-transform: uppercase;
}
.padded-top {
  padding-top: 30px;
}
/*.hide
{
    display:none;
}*/
.mbss-hide {
  display: none;
}
.mbss-banner-layer {
  position: absolute;
  top: 0px;
  left: 0px;
}
.mbss-banner-item .mbss-banner-text {
  /*position:absolute;
    left:0px;
    top:0px;*/
}
/* Product View */
.nav-tabs {
  font-size: 12px;
}
#main-image-wrapper a {
  cursor: zoom-in;
}
/*.qtyfield
{
    width:50px;
}
td .qtyfield
{
    width:100%;
    max-width:70px;
}*/
/* checkout */
#top-cart-holder {
  margin: 15px;
  display: none;
}
table.cart-table th {
  font-size: 13px;
}
table.cart-table tbody > tr > td,
table.cart-table tbody > tr > th {
  padding: 2px;
}
#billing,
#delivery {
  font-size: 12px;
}
.container.page.cookie-policy {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pixel-perfect-panel {
  max-width: none;
}
.product-mini-images div:first-child {
  padding-left: 1px;
}
/* blog */
.post-share-buttons {
  padding: 20px 0;
}
.fb-share-wrapper {
  float: left;
  margin-right: 15px;
}
.twitter-share-wrapper {
  float: left;
  margin-right: 15px;
}
.google-share-wrapper {
  overflow: auto;
  float: left;
}
.core-hidden {
  display: none;
}
.mbss-image-scale {
  max-width: 100%;
  height: auto;
}
#cookie-bar {
  background: #3D3D3D !important;
  padding: 5px 40px !important;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 22px !important;
}
#cookie-bar a.cookie-link {
  color: #FFF;
  text-decoration: underline;
  padding: 0;
  margin-left: 0;
}
#cookie-bar a.cb-enable {
  padding: 3px 8px;
}
#cookie-bar .cb-enable:hover {
  background: #119F9B none repeat scroll 0% 0% !important;
}
#cookie-bar .cb-enable {
  background: #008F8A none repeat scroll 0% 0% !important;
}
/* product filter */
.cat-selected {
  font-weight: bold;
  text-decoration: underline;
}
.filter-dropdown-icon {
  -moz-transition: transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  -webkit-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
}
.filter-dropdown-icon.closed {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
/* product list and view */
.productOptions.highlight {
  border: 2px solid #d00000;
}
.productOptionWarn {
  color: #d00000;
  display: none;
}
/* Size dependant styles */
/* all devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .product-nav {
    width: 100%;
    position: absolute;
    left: 0px;
    margin: 0px;
    padding: 0px;
  }
  #search {
    display: block;
  }
  /* Product View */
  .nav-tabs {
    font-size: inherit;
  }
  /* checkout */
  table.cart-table tbody > tr > td,
  table.cart-table tbody > tr > th {
    padding: 8px;
  }
  #billing,
  #delivery {
    font-size: inherit;
  }
}
